<template>
  <v-tooltip color="error" v-model="showScheduleConflictError" top>
    <template v-slot:activator="{ on }">
      <v-sheet :color="color" class="pr-2 pb-2">
        <v-dialog persistent :value="dateMenu || timeMenu" />
        <div v-if="savingSlot" class="text-xs-right pt-1 ml-1">
          <div class="subtitle">{{ $t("message.saving") }}</div>
          <v-progress-linear
            color="white"
            :indeterminate="true"
          ></v-progress-linear>
        </div>
        <div v-else-if="!loadedRulesAndSlots" class="text-xs-right pt-1 ml-1">
          <div class="subtitle">{{ $t("message.loadingScheduledSlots") }}</div>
          <v-progress-linear
            color="white"
            :indeterminate="true"
          ></v-progress-linear>
        </div>
        <v-layout v-else justify-end>
          <v-flex shrink>
            <v-menu
              :z-index="250"
              ref="dateMenu"
              v-model="dateMenu"
              :close-on-content-click="false"
              :close-on-click="false"
              :nudge-right="40"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  color="white"
                  hide-details
                  v-model="date"
                  :label="$t('message.interviewDate')"
                  prepend-icon="mdi-event"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-card>
                <v-date-picker
                  color="amber lighten-2"
                  v-model="datePickerDate"
                  :locale="$i18n.locale"
                  scrollable
                  :min="min"
                  :allowed-dates="allowedDates"
                  :show-current="false"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="amber lighten-2"
                    @click="dateMenu = false"
                    >{{ $t("message.cancel") }}</v-btn
                  >
                  <v-btn
                    color="amber lighten-2"
                    @click="$refs.dateMenu.save(datePickerDate)"
                    >{{ $t("message.ok") }}</v-btn
                  >
                </v-date-picker>
                <v-card flat width="290" v-if="showProposedDate">
                  <v-card-text>
                    <ProposeDate @ok="submitProposeDate" />
                  </v-card-text>
                </v-card>
              </v-card>
            </v-menu>
          </v-flex>
          <v-flex shrink>
            <v-menu
              :z-index="250"
              ref="timeMenu"
              v-model="timeMenu"
              :close-on-content-click="false"
              :close-on-click="false"
              :nudge-right="40"
              :return-value.sync="selectedSlot"
              transition="scale-transition"
              offset-y
              min-width="290px"
              max-height="50vh"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  color="white"
                  :value="selectedSlotText"
                  :label="$t('message.interviewTime')"
                  prepend-icon="mdi-clock-outline"
                  readonly
                  hide-details
                  :disabled="!date || dateMenu"
                  v-on="on"
                  :append-outer-icon="immediate ? null : 'mdi-send'"
                  @click:append-outer="saveSlot"
                ></v-text-field>
              </template>
              <v-card>
                <v-card-actions>
                  <v-spacer />
                  <v-btn text color="amber lighten-2" @click="cancelTimeMenu">{{
                    $t("message.cancel")
                  }}</v-btn>
                  <v-btn
                    color="amber lighten-2"
                    @click="$refs.timeMenu.save(tempSelectedSlot)"
                    >{{ $t("message.ok") }}</v-btn
                  >
                </v-card-actions>
                <v-card-actions>
                  <v-layout column>
                    <v-flex
                      wrap
                      xs12
                      class="mt-2"
                      v-for="slot in calculatedSlots"
                      :key="generateTimeStringFromDate(slot.startTime)"
                    >
                      <v-btn
                        block
                        large
                        :outlined="tempSelectedSlot !== slot"
                        @click="onSelectSlot(slot)"
                        >{{ generateTimeStringFromDate(slot.startTime) }}</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-card-actions>
                <v-card-actions>
                  <v-spacer />
                  <v-btn text color="amber lighten-2" @click="cancelTimeMenu">{{
                    $t("message.cancel")
                  }}</v-btn>
                  <v-btn
                    color="amber lighten-2"
                    @click="$refs.timeMenu.save(tempSelectedSlot)"
                    >{{ $t("message.ok") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-flex>
        </v-layout>
        <Popup
          v-bind="showConfirmPopupProp"
          @confirm="confirmSaveSlot"
          @cancel="revertToPreviousValue"
          @update:showDialog="revertToPreviousValue"
        />
      </v-sheet>
    </template>
    <span>{{ $t("message.alreadyBookedMessage") }}</span>
  </v-tooltip>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { getDay, startOfTomorrow, format, closestTo, addDays, eachDayOfInterval, differenceInMinutes, compareAsc, isEqual } from 'date-fns'
import { generateTimeStringFromDate, generateDateFromTimeString } from '@/helpers/calendar-slots'
import debounce from 'lodash.debounce'
import * as Sentry from '@sentry/browser'
import Popup from '@/components/calendar/Popup'
import ProposeDate from './propose-date'
import { calculateSlots } from '@appsocially/schedulerly-client/src/helpers/rule'
import { parseDateTimeString, toDate } from '@appsocially/schedulerly-client/src/helpers/time'
import { ja, enUS } from 'date-fns/locale'
import i18n from '@/plugins/i18n'
const { mapState: mapStateCalendar, mapActions: mapActionsCalendar } = createNamespacedHelpers('calendar')

const tomorrow = startOfTomorrow()
export default {
  components: {
    Popup,
    ProposeDate
  },
  i18n: { // `i18n` option, setup locale info for component
    messages: {
      en: { message: {
        saving: 'Saving...',
        loadingScheduledSlots: 'Loading...',
        interviewDate: 'Interview date',
        interviewTime: 'Interview time',
        cancel: 'Cancel',
        ok: 'OK',
        alreadyBookedMessage: 'The interview slot you have selected has already been booked. Please choose another one.',
        minutes: 'one minute | {minutes} minutes',
        confirmMessage: 'Are you sure you want to reschedule this applicant from {from} to {to}?',
        yes: 'Yes',
        no: 'No',
        date: 'MM/dd (eee) HH:mm'
      } },
      ja: { message: {
        saving: '保存中…',
        loadingScheduledSlots: '読込み中…',
        interviewDate: '面接日付',
        interviewTime: '面接時間',
        cancel: 'キャンセル',
        ok: '決定',
        alreadyBookedMessage: '選択した時間帯は、確定済みでした。再度、他の時間帯を選択して下さい。',
        minutes: '{minutes}分',
        confirmMessage: '{from}から{to}へ変更依頼してよろしいですか？',
        yes: 'はい',
        no: 'いいえ',
        date: 'MM月dd日 (eee) HH:mm'
      } }
    }
  },
  props: {
    sendInput: {
      type: Function,
      required: false
    },
    state: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      default: 'amber lighten-2'
    },
    initialValues: {
      type: Object,
      default: () => {}
    },
    immediate: {
      type: Boolean,
      default: false
    },
    confirm: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const { isoDate = null, duration, startTime } = this.initialValues || {}
    const initialSelectedSlot = startTime ? { duration: duration, startTime: parseDateTimeString(`${isoDate} ${startTime}`) } : null
    return {
      date: isoDate || null,
      datePickerDate: isoDate || null,
      tempSelectedSlot: initialSelectedSlot,
      min: format(tomorrow, 'yyyy-MM-dd'),
      dateMenu: false,
      timeMenu: false,
      selectedSlot: initialSelectedSlot,
      previousSelectedSlot: null,
      savingSlot: false,
      showScheduleConflictError: false,
      showConfirmPopup: false,
      confirmSaveSlot: async () => {
        this.showConfirmPopup = false
        this.savingSlot = true
        this.createInterviewDebounced(this.transformedToSaveSlot)
          .then(() => {
            this.savingSlot = false
            this.sendInput(this.transformedToSaveSlot)
          })
      }
    }
  },
  computed: {
    ...mapStateCalendar(['loadedRulesAndSlots', 'scheduledSlots', 'scheduledRules', 'showRescheduleConfirmation', 'showProposedDate']),
    canSaveSlot () {
      return this.selectedSlot && !this.savingSlot
    },
    shouldAutomaticallySaveSlot () {
      return this.canSaveSlot && this.immediate
    },
    daysOfWeek () {
      const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = this.scheduledRules
      return {
        0: sunday,
        1: monday,
        2: tuesday,
        3: wednesday,
        4: thursday,
        5: friday,
        6: saturday
      }
    },
    availableTimeList () {
      return this.calendar.availableTimes
    },
    startTime () {
      return parseDateTimeString(`${this.date} ${this.scheduledRules.start}`)
    },
    endTime () {
      return parseDateTimeString(`${this.date} ${this.scheduledRules.end}`)
    },
    calculatedSlots () {
      const dayOfWeek = getDay(new Date(this.date))
      const generateSlots = this.daysOfWeek[dayOfWeek]

      let slots = []
      if (generateSlots) {
        slots = slots.concat(calculateSlots(this.scheduledRules.duration || 30, this.startTime, this.endTime, this.scheduledRules.ignoredStartTimes))
      }

      slots = slots.concat(
        this.scheduledSlots
          .filter(({ from, attendees, maxAttendees }) => {
            return (attendees || []).length < (maxAttendees || 1)
          })
          .map(slot => {
            const { id, to, from } = slot
            const startTime = from
            const endTime = to
            const duration = differenceInMinutes(endTime, startTime)
            return { id, duration, startTime, date: toDate(startTime) }
          })
          .filter(slot => slot.date === this.date)
          .map(slot => {
            const { id, duration, startTime } = slot
            return { id, duration, startTime }
          })
      )

      return slots.sort((lSlot, rSlot) => compareAsc(lSlot.startTime, rSlot.startTime))
    },
    selectedSlotText () {
      if (this.selectedSlot) {
        const startTimeString = generateTimeStringFromDate(this.selectedSlot.startTime)
        return startTimeString
      } else {
        return null
      }
    },
    transformedToSaveSlot () {
      const { duration, startTime: startTimeAsDate, id: schedulerlySlotId = null } = this.selectedSlot
      return {
        schedulerlySlotId,
        isoDate: this.date,
        duration,
        startTime: generateTimeStringFromDate(startTimeAsDate)
      }
    },
    firstAvailableDate () {
      const weekFromTomorrow = addDays(tomorrow, 7)
      const allowedDays = eachDayOfInterval({ start: tomorrow, end: weekFromTomorrow })
        .filter(d => this.allowedDates(d))
      if (allowedDays && allowedDays.length > 0) {
        const earliestDate = closestTo(tomorrow, allowedDays)
        return format(earliestDate, 'yyyy-MM-dd')
      } else {
        return format(tomorrow, 'yyyy-MM-dd')
      }
    },
    applicantId () {
      return this.state.applicantId
    },
    applicantProfile () {
      return {
        name: this.state.applicantName,
        contact: this.state.applicantEmail || this.state.applicantPhone,
        email: this.state.applicantEmail,
        phoneNumber: this.state.applicantPhone
      }
    },
    showConfirmPopupProp () {
      const to = this.selectedSlot ? this.dateTimeFormatter(this.selectedSlot.startTime) : ''
      const from = this.previousSelectedSlot ? this.dateTimeFormatter(this.previousSelectedSlot.startTime) : ''
      return {
        showDialog: this.showConfirmPopup,
        title: this.$t('message.confirmMessage', { to, from }),
        confirm: this.$t('message.yes'),
        cancel: this.$t('message.no'),
        color: 'amber lighten-2'
      }
    }
  },
  watch: {
    date (isoDate) {
      this.selectedSlot = null
    },
    selectedSlot (newVal, oldVal) {
      // Remove the error when they select a different time slot
      this.showScheduleConflictError = false
      if (!this.previousSelectedSlot || !isEqual(this.previousSelectedSlot.startTime, newVal.startTime)) {
        // When the user select a new date then a new time oldVal is null, which is not correct.
        // this.previousSelectedSlot should be null only if the is an interview creation
        this.previousSelectedSlot = oldVal || this.previousSelectedSlot

        if (this.shouldAutomaticallySaveSlot) {
          this.saveSlot()
        }
      }
    },
    firstAvailableDate: {
      immediate: true,
      handler (first) {
        if (first && this.loadedRulesAndSlots && !this.datePickerDate) {
          this.datePickerDate = first
        }
      }
    }
  },
  mounted () {
    this.getScheduledSlotsAndRules({ withAttendeeData: true })
  },
  methods: {
    ...mapActionsCalendar(['getScheduledSlotsAndRules', 'createInterview']),
    allowedDates (isoDate) {
      const date = new Date(isoDate)
      const dayOfWeek = getDay(date)

      const hasSlot = this.scheduledSlots.some(slot => toDate(slot.from) === isoDate)
      if (hasSlot) {
        return hasSlot
      }

      let allowedDay = this.daysOfWeek[dayOfWeek]
      if (allowedDay) {
        const isoDateString = toDate(date)
        const startTime = parseDateTimeString(`${isoDateString} ${this.scheduledRules.start}`)
        const endTime = parseDateTimeString(`${isoDateString} ${this.scheduledRules.end}`)
        const validTimeSlots = calculateSlots(this.scheduledRules.duration || 30, startTime, endTime, this.scheduledRules.ignoredStartTimes)
        allowedDay = validTimeSlots.length > 0
      }
      return allowedDay
    },
    onUserSendInput () {

    },
    onSelectSlot (slot) {
      this.tempSelectedSlot = slot
    },
    cancelDateMenu () {
      this.dateMenu = false
    },
    cancelTimeMenu () {
      this.timeMenu = false
    },
    generateDateFromTimeString,
    generateTimeStringFromDate,
    createInterviewDebounced: debounce(function (slot) {
      return this.createInterview({
        slot: {
          timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
          ...slot
        },
        applicantId: this.applicantId,
        applicantProfile: this.applicantProfile,
        isConfirmed: false
      })
    }, 3000, {
      leading: true,
      trailing: false
    }),
    async saveSlot () {
      if (!this.canSaveSlot) {
        return
      }

      this.savingSlot = true
      try {
        if (this.showRescheduleConfirmation && this.previousSelectedSlot) {
          this.showConfirmPopup = true
        } else {
          await this.confirmSaveSlot()
        }
      } catch (err) {
        Sentry.captureException(err)
        console.error('Problem saving schedule slot', err) // eslint-disable-line no-console
        this.selectedSlot = false
        this.showScheduleConflictError = true
        this.savingSlot = false
      }
    },
    revertToPreviousValue () {
      this.selectedSlot = this.previousSelectedSlot
      this.tempSelectedSlot = this.previousSelectedSlot
      this.showConfirmPopup = false
    },
    dateTimeFormatter (date) {
      return format(date, this.$t('message.date'), {
        locale: i18n.locale === 'ja' ? ja : enUS
      })
    },
    submitProposeDate ({ dates }) {
      this.dateMenu = false
      this.sendInput({ proposedDates: dates })
    }
  }
}
</script>
<style>
</style>
