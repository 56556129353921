import {
  startOfToday,
  setHours,
  setMinutes,
  differenceInMinutes,
  addMinutes,
  areIntervalsOverlapping
  // isEqual,
  // addDays,
  // isMonday,
  // isTuesday,
  // isWednesday,
  // isThursday,
  // isFriday,
  // isSaturday,
  // isSunday,
  // format
} from 'date-fns'
import range from 'lodash.range'

// import { DateTimeHelper } from '@appsocially/timepassport'

/**
 * Convert a string like '10:30' into a datetime
 * @param {String} timeString
 */
export function generateDateFromTimeString (timeString) {
  const [hoursString, minutesString] = timeString.split(':')
  const hours = Number.parseInt(hoursString)
  const minutes = Number.parseInt(minutesString)
  const baseDate = startOfToday()
  const hoursDate = setHours(baseDate, hours)
  const finalDate = setMinutes(hoursDate, minutes)
  return finalDate
}

export function generateTimeStringFromDate (date) {
  const hoursFullWidth = `${date.getHours()}`.padStart(2, '0')
  const minutesFullWidth = `${date.getMinutes()}`.padStart(2, '0')
  return `${hoursFullWidth}:${minutesFullWidth}`
}

export function openSlotsList (slotDuration, startTime, endTime) {
  const totalTimePeriod = differenceInMinutes(endTime, startTime)
  const totalSlots = totalTimePeriod / slotDuration
  return range(totalSlots).map(slotNumber => {
    return {
      startTime: addMinutes(startTime, slotNumber * slotDuration),
      duration: slotDuration
    }
  })
}

export function checkedSlotsList (slots, scheduledSlots) {
  return slots.map(({ startTime, duration }) => {
    const endTimeInitial = addMinutes(startTime, duration)

    const isOpen = !scheduledSlots.some(scheduled => {
      const {
        startTime: scheduledStart,
        duration: scheduledDuration
      } = scheduled
      const scheduledEnd = addMinutes(scheduledStart, scheduledDuration)
      return areIntervalsOverlapping(
        { start: startTime, end: endTimeInitial },
        { start: scheduledStart, end: scheduledEnd }
      )
    })

    return {
      startTime,
      duration,
      isOpen
    }
  })
}

// /**
//  *
//  * @param {Integer} slotDuration
//  * @param {Date} startTime
//  * @param {Date} endTime
//  */
// export function calculateSlots (
//   slotDuration,
//   startTime,
//   endTime,
//   ignore = []
// ) {
//   return openSlotsList(slotDuration, startTime, endTime)
//     .filter(slot => {
//       let shouldIgnore = !ignore.some(start => {
//         return isEqual(start, slot.startTime)
//       })
//       return shouldIgnore
//     })
// }

// export function formatDateTime (date) {
//   return format(date, 'yyyy-MM-dd HH:mm')
// }

// export function isoToVuetify (dateTime) {
//   // FIXME: Move this to timepassport, temporary placed here because the "isoToVuetify" implemenation is too stupid to keep
//   // The 3 functions toDate, toTime, formatDateTime should be able to replace this stupid function
//   return {
//     date: toDate(dateTime),
//     time: toTime(dateTime),

//     get dateTime () {
//       return format(dateTime, 'yyyy-MM-dd HH:mm')
//     }

//   }
// }

// export function toDate (dateTime) {
//   // FIXME: Move this to timepassport
//   return format(dateTime, 'yyyy-MM-dd')
// }

// export function toTime (dateTime) {
//   // FIXME: Move this to timepassport
//   return format(dateTime, 'HH:mm')
// }

// export function generatedUserSlots (startDate, rules) {
//   let generatedSlots = []

//   if (!rules) {
//     return generatedSlots
//   }

//   const { monday, tuesday, wednesday, thursday, friday, saturday, sunday, start, end, ignoredStartTimes } = rules
//   const excludedTimes = ignoredStartTimes.map(start => start)

//   const numberOfDays = 31
//   const duration = 30

//   for (let i = 0; i < numberOfDays; i++) {
//     const date = addDays(startDate, i)
//     const dateString = toDate(date)

//     if (
//       (isMonday(date) && monday) ||
//       (isTuesday(date) && tuesday) ||
//       (isWednesday(date) && wednesday) ||
//       (isThursday(date) && thursday) ||
//       (isFriday(date) && friday) ||
//       (isSaturday(date) && saturday) ||
//       (isSunday(date) && sunday)
//     ) {
//       generatedSlots.push(...calculateSlots(duration, DateTimeHelper.parse(`${dateString} ${start}`), DateTimeHelper.parse(`${dateString} ${end}`), excludedTimes))
//     }
//   }
//   return generatedSlots
// }
