<template>
  <v-dialog v-model="dialogModel" width="400">
    <template v-slot:activator="{ on }">
      <div>
        {{$t('message.proposePre')}}
        <a @click="openDialog" v-on="on">{{$t('message.here')}}</a>
        {{$t('message.proposeSuf')}}
      </div>
    </template>
    <v-card>
      <v-card-title>{{$t('message.title')}}</v-card-title>
      <v-card-subtitle>{{$t('message.subTitle')}}</v-card-subtitle>
      <v-card-text>
        <v-layout justify-end>
          <v-flex shrink>
            <v-menu
              :z-index="500"
              ref="isoDateMenu"
              v-model="isoDateMenu"
              :close-on-content-click="false"
              :close-on-click="false"
              :nudge-right="40"
              :return-value.sync="isoDate"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  hide-details
                  v-model="isoDate"
                  :label="$t('message.date')"
                  prepend-icon="mdi-calendar-outline"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-card>
                <v-card-actions>
                  <v-date-picker
                    :min="minDate"
                    color="amber lighten-2"
                    format="24hr"
                    v-model="isoDate"
                  ></v-date-picker>
                </v-card-actions>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    text
                    color="amber lighten-2"
                    @click="isoDateMenu = false"
                  >{{$t('message.cancel')}}</v-btn>
                  <v-btn
                    color="amber lighten-2"
                    @click="$refs.isoDateMenu.save(isoDate)"
                  >{{ $t('message.select')}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-flex>
          <v-flex shrink>
            <v-menu
              :z-index="500"
              ref="isoTimeMenu"
              v-model="isoTimeMenu"
              :close-on-content-click="false"
              :close-on-click="false"
              :nudge-right="40"
              :return-value.sync="isoTime"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  color="white"
                  :value="isoTime"
                  :label="$t('message.time')"
                  prepend-icon="mdi-clock-outline"
                  readonly
                  hide-details
                  :disabled="!isoDate || isoDateMenu"
                  v-on="on"
                  append-outer-icon="mdi-send"
                >
                  <template v-slot:append-outer>
                    <v-btn
                      small
                      outlined
                      @click="addTime"
                      :disabled="!allowAdd"
                    >{{$t('message.add')}}</v-btn>
                  </template>
                </v-text-field>
              </template>
              <v-card>
                <v-card-actions>
                  <v-time-picker
                    ref="time-picker"
                    color="amber lighten-2"
                    :allowed-minutes="allowedMinutes"
                    format="24hr"
                    v-model="isoTime"
                  ></v-time-picker>
                </v-card-actions>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    text
                    color="amber lighten-2"
                    @click="isoTimeMenu = false"
                  >{{$t('message.cancel')}}</v-btn>
                  <v-btn
                    color="amber lighten-2"
                    @click="$refs.isoTimeMenu.save(isoTime)"
                  >{{$t('message.select')}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-text>
        <v-list subheader>
          <v-list-item v-for="(date, index) in dates" :key="date.iso" @click="openDialog">
            <v-list-item-content>
              <v-list-item-title v-text="date.iso"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn @click="remove(index)" icon>
                <v-icon color="grey lighten-1">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialogModel = false" color="amber lighten-2">{{$t('message.cancel')}}</v-btn>
        <v-btn
          :loading="isComplete"
          :disabled="disableOk"
          @click="addProposeDate"
          color="amber lighten-2"
        >{{$t('message.select')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import { applicantSuggestInterviewDates } from '@/helpers/firebase-functions'
import { createNamespacedHelpers } from 'vuex'
import { startOfTomorrow } from 'date-fns'
import { toDate } from '@appsocially/schedulerly-client/src/helpers/time'
const { mapState: mapStateCalendar } = createNamespacedHelpers('calendar')

export default {
  data () {
    return {
      dialogModel: false,
      isoDate: null,
      isoTime: null,
      isoDateMenu: false,
      isoTimeMenu: false,
      dates: [],
      isComplete: false
    }
  },
  methods: {
    openDialog () {
      this.$emit('open')
    },
    addTime () {
      this.dates.push({ iso: `${this.isoDate} ${this.isoTime}`, isoDate: this.isoDate, startTime: this.isoTime, timeZone: new Intl.DateTimeFormat().resolvedOptions().timeZone, duration: 30 })
      this.isoDate = null
      this.isoTime = null
      this.$refs['time-picker'].selecting = 1
    },
    async addProposeDate () {
      this.isComplete = true
      await applicantSuggestInterviewDates({ ownerId: this.ownerId, dates: this.dates })
      this.isComplete = false
      this.dialogModel = false

      this.$emit('ok', { dates: this.dates })
    },
    allowedMinutes: v => v % 5 === 0,
    remove (index) {
      this.dates.splice(index, 1)
    }
  },
  computed: {
    ...mapStateCalendar(['subscriptionInfo']),
    ownerId () {
      const { ownerId } = this.subscriptionInfo
      return ownerId
    },
    disableOk () {
      return this.dates.length === 0
    },
    minDate () {
      return toDate(startOfTomorrow())
    },
    allowAdd () {
      return this.isoDate && this.isoTime
    }
  },
  i18n: {
    messages: {
      en: { message: {
        title: 'Proposed Date',
        subTitle: 'Select the date and time you would like',
        date: 'Date',
        time: 'Time',
        add: 'Add',
        select: 'OK',
        cancel: 'Cancel',
        proposePre: 'Click ',
        proposeSuf: 'to propose the interview dates/times, if you can\'t find a convenient slot for you ',
        here: 'here'
      } },
      ja: { message: {
        title: 'ご希望の面接日時',
        subTitle: '候補日時をいくつかご提案ください。',
        date: '日付',
        time: '時間',
        add: '追加',
        select: '決定',
        cancel: 'キャンセル',
        proposePre: 'ご都合の良い日時がない場合は、',
        proposeSuf: 'をクリックしてください。',
        here: 'こちら'
      } }
    }
  }
}
</script>
